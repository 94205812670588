import React, { Component } from 'react';
import { Link } from 'gatsby';
import logo from './../../images/logo1.png';

class Footer extends Component {
  render() {
    return (
      <section className="footer-section">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-sm-7">
                <div className="footer-logo">
                  <a href="/">
                    <img src={logo} alt="Tokinfo" />
                  </a>
                </div>
              </div>

              <div className="col-sm-5">
                <div className="footer-info text-right">
                  <p>
                  Tokinfo helps you to convey any text message using only numbers while on a voice call. Numbers are easier to pronounce and understand than alphabetic letters while you are on a voice call. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  footer top end */}

        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="social-links">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a target="_blank" href="https://facebook.com/tkn.fo">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a target="_blank" href="https://twitter.com/tkn_fo">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    {/* <li className="list-inline-item">
                      <a href="#">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>

              <div className="col-md-6">
                <div className="copyright text-center">
                  <p>
                    &copy; 2020 TokInfo - Developed & Maintained by{' '}
                    <a href="#">DevSpike</a>
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="important-link text-right">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <Link to="/terms">Terms</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/privacy">Privacy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Footer;
